import { FormEvent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import subscriptionApi from "../apis/subscription";
import Pagination from "../components/Pagination";
import DocumentTitle from "../utils/DocumentTitle";
import Loading from "../components/Loading";

const Page = () => {

  const itemsPerPage = 25;
  const [ search, setSearch ] = useState<string>('');
  const [ subscriptionData, setSubscriptionData ] = useState<Subscription[]>([]);
  const [ nextPage, setNextPage ] = useState<boolean>(true);
  const [ loaded, setLoaded ] = useState<boolean>(false);
  const [ skip, setSkip ] = useState<number>(0);

  // Form
  const [ formData, setFormData ] = useState<{ [key: string]: string }>({});
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSkip(0);
    setSearch(formData.search);
  }

  // Fetch
  useEffect(() => {
    subscriptionApi.getList(skip, itemsPerPage, search)
      .then((r) => {
        setSubscriptionData([ ...r.data ]);
        setNextPage(skip < r.total && r.data.length >= itemsPerPage);
        setLoaded(true);
      })
      .catch((e) => toast.error(e));
  }, [ skip, itemsPerPage, search ]);

  useEffect(() => {
    DocumentTitle('Subscriptions');
  }, []);

  return (
    <div className="tw-container tw-padding">
      <div className="flex flex-wrap justify-between">
        <h1>Subscriptions</h1>
        <form onSubmit={handleSubmit} className="my-auto w-full lg:w-96">
          <input 
            type="search" 
            name="search" 
            className="w-full"
            placeholder="Search by Email, Customer or Subscription Id"
            defaultValue={formData.search} 
            onChange={(e) => setFormData({ ...formData, search: e.target.value })} 
          />
        </form>
      </div>

      <div className="table mt-4">
        <div className="head">
          <div className="w-64">Customer</div>
          <div className="w-64 hm">Subscription</div>
          <div className="w-52 hm">Status</div>
          <div className="w-32 hm-m ml-auto">Created</div>
        </div>

        {/* Data */}
        {!loaded && (
          <div className="bg-gray-100 rounded-xl mt-6 text-center py-10">
            <Loading partial />
            <div className="mt-4 opacity-80">Searching Subscriptions...</div>
          </div>
        )}
        {loaded && subscriptionData.map((data) => (
          <Link key={data._id} className="row hover:bg-gray-200 hover:bg-opacity-70 hover:text-white transition ease-in-out duration-100" to={`/users/${data._id}`}>
            <div className="w-64">
              <div className="truncate" title={data.email}>{data.email || 'unknown@mathgames.com'}</div>
              <div style={{ marginTop: '-10px' }} className="text-xs opacity-60">{data.customer_id}</div>
            </div>
            <div className="w-64 hm">
              <div className="truncate" title={data?.planName || data?.plan}>{data?.planName || data?.plan}</div>
              <div style={{ marginTop: '-10px' }} className="text-xs opacity-60">
                {data.subscription_id}
              </div>
            </div>
            <div className="w-52 hm">
              <div>{String(data?.status || '').substring(0, 1).toUpperCase()}{String(data?.status || '').substring(1, data.status?.length)}</div> 
              <div style={{ marginTop: '-10px' }} className="text-xs opacity-60">
                ${Number((data?.amount || 0) / 100).toFixed(2)} / {data.role}
              </div>
            </div>
            <div className="hm-m w-32 ml-auto">{String(data?.created_date || '2001-01-01').split('T')[0]}</div>
          </Link> 
        ))}

        {/* Pagination */}
        {loaded && 
          <Pagination 
            skip={skip}
            isNextPage={nextPage} 
            nextPage={() => setSkip(skip + itemsPerPage)} 
            prevPage={() => setSkip(skip - itemsPerPage)} 
          />
        }
      </div>
    </div>
  )
}

export default Page;