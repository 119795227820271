import { useEffect, useState } from "react";
import districtApi from "../apis/district";
import Loading from "../components/Loading";
import DocumentTitle from "../utils/DocumentTitle";

const Page = () => {

  const [ loaded, setLoaded ] = useState<boolean>(false);
  const [ data, setData ] = useState<{ [key: string]: number }>({
    students: 0,
    teachers: 0,
    assignments: 0,
    currentAssignments: 0,
    expiredAssignments: 0,
    currentAssessments: 0,
    totalProgressions: 0
  });

  useEffect(() => {
    districtApi.getAnalyticsById()
      .then((r) => {
        if (!r.error) {
          setData({ ...r });
          setLoaded(true);
        }
      })
      .catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    DocumentTitle('Analytics');
  }, []);

  const arrayData = [
    { name: 'Teachers', value: Number(data?.teachers || 0) },
    { name: 'Students', value: Number(data?.students || 0) },
    { name: 'Assignments', value: Number(data?.assignments || 0) },
    { name: 'Progressions', value: Number(data?.totalProgressions || 0) },
    { name: 'Current Assignments', value: Number(data?.currentAssignments || 0) },
    { name: 'Expired Assignments', value: Number(data?.expiredAssignments || 0) },
    { name: 'Current Assessments', value: Number(data?.currentAssessments || 0) },
  ]

  return (
    <div className="tw-container tw-padding">
      <div className="mt-6">
        <h1>Analytics</h1>
        {loaded && (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mt-6">
            {arrayData.map((item, index) => (
              <div key={index} className="bg-gray-100 rounded-xl flex flex-col justify-center items-center py-8">
                <div className="h1">{item.value}</div>
                <div className="text-gray-600 text-xs uppercase text-center pt-3">{item.name}</div>
              </div>
            ))}
          </div>
        )}
        {!loaded && (
          <div className="bg-gray-100 rounded-xl mt-6 text-center py-16">
            <Loading partial />
            <div className="mt-4 opacity-80">Loading Analytics...</div>
          </div>
        )}
        
      </div>
    </div>
  )
}

export default Page;