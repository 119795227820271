import { ChangeEvent, useEffect, useState } from "react";
import { toast } from "react-toastify";
import userApi from "../../apis/user";
import curriculumApi from "../../apis/curriculum";
import Modal from "../Modal";
import { useUserContext } from "../../contexts/User.context";

type FieldChangeEvent = ChangeEvent<HTMLInputElement | HTMLSelectElement>

interface IProps {
  data: User | null;
  isOpened: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const UpdateModal = (props: IProps) => {

  const { data, isOpened, onClose, onSubmit } = props;
  const { userRole } = useUserContext();

  const [ curriculumData, setCurriculumData ] = useState<{ label: string, value: string }[]>([]);
  const [ formData, setFormData ] = useState<{ [key: string]: string | number | boolean }>({
    _id: "",
    first_name: "",
    last_name: "",
    user_name: "",
    email: "",
    role: "student",
    curriculum_key: "",
    subscription: "",
  });

  // Effect render when data is changed (or when component is opened)
  useEffect(() => {
    setFormData({
      _id: String(data?._id),
      first_name: String(data?.first_name),
      last_name: String(data?.last_name),
      user_name: String(data?.user_name || ''),
      email: String(data?.email),
      role: String(data?.role || 'Student'),
      curriculum_key: String(data?.curriculum_key || ''),
      subscription: String(data?.subscription?._id || ''),
    });
  }, [ data ]);

  useEffect(() => {
    curriculumApi.getListForSelect()
      .then(async (r) => setCurriculumData([ ...r ])).catch((e) => toast.error(e));
  }, []);

  // Form Methods
  const handleSubmit  = () => {
    userApi.update(
      String(formData._id), 
      { 
        ...formData,
        resetOAuth: String(formData.email !== data?.email),
      },
      userRole === 'district'
    ).then((r) => {
      if (!r.errors && !r.error) {
        toast.success("Updated");
        onSubmit();
        onClose();
      } else 
        toast.error(r?.errors || r?.error);
    })
  };

  // Configuration Options
  const confOptions = [
    { label: "First Name", value: formData.first_name, note: '', onChange: (e: FieldChangeEvent) => setFormData({ ...formData, first_name: e.target.value }), role: 'all' },
    { label: "Last Name", value: formData.last_name, note: '', onChange: (e: FieldChangeEvent) => setFormData({ ...formData, last_name: e.target.value }), role: 'all' },
    { label: "Username", value: formData.user_name, note: '', onChange: (e: FieldChangeEvent) => setFormData({ ...formData, user_name: e.target.value }), role: 'admin' },
    { label: "Email Address", value: formData.email, note: userRole === 'district' ? 'Must contain your district namespace' : 'Changing will reset all OAuth links if they exist.', onChange: (e: FieldChangeEvent) => setFormData({ ...formData, email: e.target.value }), role: 'all' },
    { label: "Role", value: formData.role, note: '', onChange: (e: FieldChangeEvent) => setFormData({ ...formData, role: e.target.value }), options: [ 
      { label: "Student", value: "student" },
      { label: "Teacher", value: "teacher" },
      { label: "Parent", value: "parent" }
    ], role: 'admin' },
    { label: "Curriculum", value: formData.curriculum_key, note: '', onChange: (e: FieldChangeEvent) => setFormData({ ...formData, curriculum_key: e.target.value }), options: curriculumData, role: 'admin' },
    { label: "Subscription", value: formData.subscription, note: '"Incorrectly changing this can be damaging, use at your own risk!"', onChange: (e: FieldChangeEvent) => setFormData({ ...formData, subscription: e.target.value }), role: 'admin' },
  ]

  return (
    <>
      {/* Modal */}
      <Modal isOpened={isOpened} onClose={onClose}>
        <h2 className="h3 mb-3">Update {data?.first_name || 'Unknown'} {data?.last_name} ({data?.user_name})</h2>
        <form method="put" className="grid grid-cols-1 gap-4" onSubmit={handleSubmit}>
          {confOptions.map((item) => (item.role === 'all' || item.role === userRole) && (
            <div key={item.label}>
              {item.options ? (
                <>
                  <label>{item.label}</label>
                  {item.note && <div className="text-sm opacity-80">{item.note}</div>}
                  <select onChange={item.onChange} value={String(item.value)}>
                    <option value="">Select</option>
                    {item.options.map((option) => (
                      <option key={option.label} value={option.value}>{option.label}</option>
                    ))}
                  </select>
                </>
              ) : (
                <>
                  <label>{item.label}</label>
                  {item.note && <div className="text-sm opacity-70 -mt-1.5 mb-2">{item.note}</div>}
                  <input type="text" name={item.label} defaultValue={String(item.value)} onChange={item.onChange} />
                </>
              )}
            </div>
          ))}

          <div className="col-span-full flex space-x-2 justify-end">
            <button type="button" className="button teal" onClick={handleSubmit}>Submit</button>
          </div>
        </form>
      </Modal>
    </>
  );
}

export default UpdateModal;