import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import userApi from "../../apis/user";
import Modal from "../Modal";
import { useUserContext } from "../../contexts/User.context";

interface IProps {
  data: User | null;
  isOpened: boolean;
  onClose: () => void;
}

const PasswordModal = (props: IProps) => {

  const { data, isOpened, onClose } = props;
  const { userRole } = useUserContext();

  const [ generated, setGenerated ] = useState<string>('');

  // Effect render when data is changed (or when component is opened)
  useEffect(() => {
    setGenerated('');
  }, [ data ]);

  // Form Methods
  const handleSubmit  = () => {
    userApi.forgot(String(data?._id), userRole === 'district').then((r) => {
      if (!r.errors) {
        setGenerated(r.password_url);
      } else 
        toast.error(r.errors);
    })
  };

  const handleCopy = () => {
    navigator?.clipboard?.writeText(generated);
    toast.success("Copied");
  }

  return (
    <>
      {/* Modal */}
      <Modal isOpened={isOpened} onClose={onClose} size="small">
        <h2 className="h3 mb-3">Password Reset {data?.first_name || 'Unknown'} {data?.last_name} ({data?.user_name})</h2>
        <div className="grid grid-cols-1 gap-4">
          <p>
            This will reset the password for {data?.first_name || 'Unknown'} {data?.last_name} ({data?.user_name}).  
          </p>        

          <div className="flex">
            <input type="text" className="rounded-none bg-gray-100 border border-gray-200 text-gray-600" value={generated} readOnly />
            <button type="button" className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4" onClick={handleCopy}>
              <FontAwesomeIcon icon={faClipboard} />
            </button>
          </div>
  
          <div className="col-span-full flex space-x-2 justify-end">
            <button type="button" className={`button teal ${generated ? 'opacity-50 pointer-events-none' : ''}`} onClick={handleSubmit}>
            Generate Password Link
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default PasswordModal;