import { FormEvent, useState } from "react";
import { toast } from "react-toastify";
import authApi from "../apis/auth";
import { useUserContext } from "../contexts/User.context";

const Page = () => {

  const { getUserData } = useUserContext();
  const [ formData, setFormData ] = useState<{ [key: string]: string }>({});

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    authApi.login(formData)
      .then((r) => {
        if (r?.errors || r?.message)
          toast.error(r?.errors || r?.message);
        else 
          getUserData();
      })
      .catch((e) => console.log(e))
  };

  return (
    <div className="flex flex-col h-screen w-full px-8 justify-center items-center bg-gradient-to-r from-gray-900 to-gray-800">
      <div className="flex justify-center mb-5">
        <img src="/logo-text.png" alt="logo" className="w-64 h-auto" />
      </div>
      <form method="post" className="rounded-2xl border w-80 md:w-96 bg-white p-6 grid grid-cols-1 gap-4" onSubmit={handleSubmit}>
          <h1>Login</h1>
          <div>
            <label>Username</label>
            <input type="text" name="user_name" placeholder="JohnDoe1988" onChange={(e) => setFormData({ ...formData, user_name: e.target.value })} required />
          </div>
          <div>
            <label>Password</label>
            <input type="password" name="password" placeholder="**********" onChange={(e) => setFormData({ ...formData, password: e.target.value })} required />
          </div>
          <button type="submit" className="button blue">Login</button>
      </form>
    </div>
  )
}

export default Page;