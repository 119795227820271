import { NavLink } from "react-router-dom";
import Config from "../Config";
import { useUserContext } from "../contexts/User.context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faCircleExclamation, faLayerGroup, faLifeRing, faMoneyBill, faPieChart, faSignOutAlt, faUserFriends } from "@fortawesome/free-solid-svg-icons";
import Avatar from "./User/Avatar";

interface IProps {
  state: boolean;
  toggle: () => void;
}

const Navbar = (props: IProps) => {

  const { userData, userRole, resetUserData } = useUserContext();
  const { state, toggle } = props;

  const handleLogout = () => {
    resetUserData();
    return setTimeout(() => window.location.reload(), 1000);
  }

  const items = [
    { name: "Users", icon: faUserFriends, path: "/", role: "admin" },
    { name: "Subscriptions", icon: faMoneyBill, path: "/subscriptions", role: "admin" },
    { name: "Events", icon: faLayerGroup, path: "/events", role: "admin" },
    { name: "Disputes", icon: faCircleExclamation, path: "/disputes", role: "admin" },
    { name: "Analytics", icon: faPieChart, path: "/", role: "district" },
    { name: "Users", icon: faUserFriends, path: "/users", role: "district" },
  ]

  const SupportButton = (
    <a className={"flex px-4 py-2 rounded-lg hover:bg-white hover:bg-opacity-5"} href={"https://teachmehelp.zendesk.com/hc/en-us"} target="_blank" rel="noopener noreferrer">
      <div className="flex space-x-4">
        <FontAwesomeIcon className="my-auto" icon={faLifeRing} />
        <div>Support</div>
      </div>
    </a>
  );

  return state ? (
    <nav className={`px-6 py-4 text-white`}>
      {/* Name & Button */}
      <div className="flex justify-between items-center">
        <img src="/logo.png" alt="Math Games" className="h-8 w-auto" />
        <div className="font-bold text-base">
          {Config.name}
        </div>
        <button type="button" onClick={toggle} className={'bg-white bg-opacity-5 hover:bg-opacity-10 rounded-lg px-2.5 py-1.5'}>
          <FontAwesomeIcon icon={faBars} />
        </button>
      </div>

      {/* District (if applicable) */}
      {userRole === 'district' && (
        <div className="bg-black bg-opacity-40 text-xs h-8 mt-3 flex items-center justify-center left-0 right-0 absolute">
          {userData?.district?.name}
        </div>
      )}

      {/* Items */}
      <div className="hidden xl:block grid grid-cols-1 gap-2 mt-14">
        {items.map((item, i) => (userRole === item.role) && (
          <NavLink key={i} className={({ isActive }) => isActive ? 'flex px-4 py-2 rounded-lg bg-white bg-opacity-10 hover:bg-white hover:bg-opacity-5' : 'flex px-4 py-2 rounded-lg hover:bg-white hover:bg-opacity-5'} to={item.path}>
            <div className="flex space-x-4">
              <FontAwesomeIcon className="my-auto" icon={item.icon} />
              <div>{item.name}</div>
            </div>
          </NavLink>
        ))}
        {SupportButton}
      </div>

      <div className="block xl:hidden grid grid-cols-1 gap-2 mt-14">
        {items.map((item, i) => (userRole === item.role) && (
          <NavLink key={i} className={({ isActive }) => isActive ? 'flex px-4 py-2 rounded-lg bg-white bg-opacity-10 hover:bg-white hover:bg-opacity-5' : 'flex px-4 py-2 rounded-lg hover:bg-white hover:bg-opacity-5'} onClick={toggle} to={item.path}>
            <div className="flex space-x-4">
              <FontAwesomeIcon className="my-auto" icon={item.icon} />
              <div>{item.name}</div>
            </div>
          </NavLink>
        ))}
        {SupportButton}
      </div>

      {/* User */}
      <div className="absolute bottom-4 flex">
        <Avatar data={userData as User} className="w-8 h-8" />
        <div className="pl-2 my-auto w-44">
          <div className="font-bold text-sm">{userData?.first_name} {userData?.last_name}</div>
          <div className="text-xs truncate">{userData?.email || ''}</div>
        </div>
        <div className="pl-4 my-auto">
          <button type="button" onClick={handleLogout}>
            <FontAwesomeIcon icon={faSignOutAlt} />
          </button>
        </div>
      </div>
    </nav>
  ) : (
    <button type="button" className="fixed top-4 left-0 pl-2 pr-3 py-2 rounded-r-lg bg-gray-900 hover:bg-gray-700 text-white" onClick={toggle}>
      <FontAwesomeIcon icon={faBars} className="text-xl" />
    </button>
  );
}

export default Navbar;