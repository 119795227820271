import { faCircleNotch } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

interface IProps {
  partial?: boolean;
}

const Loading = (props: IProps) => {
  return !props?.partial ? (
    <div className="bg-white bg-opacity-60 fixed inset-0 z-50 flex h-screen justify-center items-center transition ease-in-out">
      <FontAwesomeIcon size="2x" icon={faCircleNotch} className="opacity-70" spin />
    </div>
  ) : (
    <div className="text-center">
      <FontAwesomeIcon size="2x" icon={faCircleNotch} className="opacity-70" spin />
    </div>
  )
}

export default Loading;