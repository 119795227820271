import { FormEvent, useEffect, useState } from "react";
import { toast } from "react-toastify";
import districtApi from "../apis/district";
import { useParams } from "react-router-dom";

const Page = () => {

  const [ loaded, setLoaded ] = useState<boolean>(false);
  const [ formData, setFormData ] = useState<{ [key: string]: string }>({});
  const { district_id } = useParams();

  const sections = [
    "Account", "District"
  ];

  const fields = [
    { section: 0, id: "email", label: "Email Address", type: "email", placeholder: "john@example.com", className: 'col-span-full' },
    { section: 0, id: "first_name", label: "First Name", type: "text", placeholder: "John", className: 'col-span-1' },
    { section: 0, id: "last_name", label: "Last Name", type: "text", placeholder: "Doe", className: 'col-span-1' },
    { section: 0, id: "user_name", label: "Username", type: "text", placeholder: "JohnDoe1988", className: 'col-span-1' },
    { section: 0, id: "password", label: "Password", type: "password", placeholder: "**********", className: 'col-span-1' },
    { section: 1, id: "district_name", label: "District Name", type: "text", placeholder: "Red Deer Public School District", className: 'col-span-1' },
    { section: 1, id: "district_code", label: "District Code", type: "text", placeholder: "rdpsd", className: 'col-span-1' },
  ]

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    districtApi.createAdmin({ ...formData })
      .then((r) => {
        if (r?.error || r?.message)
          toast.error(r?.error || r?.message);
        else {
          toast.success("Account created, you may login now!");
          return window.location.href = '/';
        }
      })
      .catch((e) => toast.error("An error occurred while creating your account. Please try again later."));
  };

  useEffect(() => {
    districtApi.getOne(district_id)
      .then((r) => {
        if (r?.error || r?.message) {
          toast.error(r?.error || r?.message);
          return window.location.href = "/";
        } else {
          setFormData((data) => ({ ...data, district_id: r._id, district_name: r.name, district_code: r.code, invoice_id: r.invoice_id }));
          setLoaded(true);
        }
      });
  }, [ district_id ]);

  return loaded ? (
    <div className="flex h-auto md:h-screen px-8 py-8 md:py-0 justify-center items-center bg-gradient-to-r from-gray-900 to-gray-800">
      <form method="post" className="max-w-screen-md w-full grid grid-cols-1 gap-4" onSubmit={handleSubmit}>
          <h1 className="text-white">Create an Account</h1>
          <div className="text-white -mt-3 text-sm opacity-80">District #{district_id}</div>
          {sections.map((section, i) => (
              <div className="border rounded-2xl p-6 bg-white" key={i}>
                  <h1 className="text-xl font-bold mb-4">{section}</h1>
                  <div className="grid grid cols-1 md:grid-cols-2 gap-4">
                      {fields.map((field) => (field.section === i) && (
                          <div key={field.id} className={field.className}>
                              <label>{field.label}</label>
                              <input 
                                  key={field.id}
                                  type={field.type} 
                                  name={field.id} 
                                  placeholder={field.placeholder} 
                                  defaultValue={formData?.[field.id] || ''}
                                  onChange={(e) => setFormData({ ...formData, [field.id]: e.target.value })} 
                                  required 
                              />
                          </div>
                      ))}
                  </div>
              </div>
          ))}
          <button type="submit" className="button blue flex ml-auto">Create Account</button>
      </form>
    </div>
  ) : <></>
}

export default Page;