import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import userApi from "../apis/user";
import Loading from "../components/Loading";
import Avatar from "../components/User/Avatar";
import DeleteModal from "../components/User/DeleteModal";
import DetailSummary from "../components/User/DetailSummary";
import MigrateModal from "../components/User/MigrateModal";
import PasswordModal from "../components/User/PasswordModal";
import UpdateModal from "../components/User/UpdateModal";
import DocumentTitle from "../utils/DocumentTitle";
import { useUserContext } from "../contexts/User.context";
import { toast } from "react-toastify";

const Page = () => {

  const { _id } = useParams<{ _id: string }>();
  const { userRole, userData } = useUserContext();
  const [ data, setData ] = useState<User | null>(null);
  const [ modal, setModal ] = useState<number | null>(null);

  const getById = useCallback(async () => {
    userApi.getById(String(_id))
      .then((r) => {
        if (r) {
          DocumentTitle(`Viewing ${r.user_name}`);
          setData({ ...r })
        }
      })
      .catch((e) => console.log(e));
  }, [ _id ]);

  const assignSubscription = useCallback(async (_id: string) => {
    userApi.assignSubscription(String(_id))
      .then((r) => {
        if (!r.error)  {
          toast.success("Updated");
          getById();
        } else 
          toast.error(r.errors);
      })
      .catch((e) => console.log(e));
  }, [ getById ]);


  useEffect(() => {
    getById();
  }, [ getById ]);

  useEffect(() => {
    DocumentTitle('Finding User');
  }, []);

  return data ? (
    <>
      {/* Modal */}
      <MigrateModal data={data} isOpened={modal === 0} onClose={() => setModal(null)} />
      <UpdateModal data={data} isOpened={modal === 1} onClose={() => setModal(null)} onSubmit={getById} />
      <DeleteModal data={data} isOpened={modal === 2} onClose={() => setModal(null)} />
      <PasswordModal data={data} isOpened={modal === 3} onClose={() => setModal(null)} />
      
      {/* Page */}
      <div className="tw-container-small tw-padding">
        <div className="bg-gray-100 p-6 rounded-lg">
          <div className="flex flex-wrap justify-center lg:justify-start">
            <div>
              <Avatar data={data as User} className="w-20 h-20" />
              <div className="block mx-auto text-center w-20 mt-2 px-2 py-1 bg-teal-500 text-xs uppercase font-bold text-white rounded-lg">{data.role || 'N/A'}</div>
            </div>
            <div className="my-auto text-center lg:text-left lg:pl-4">
              <h1 className="h2">{data?.first_name || 'Unknown'} {data?.last_name} ({data?.user_name})</h1>
              <div className="text-gray-600">{data?.email}</div>
              <div className="mt-6">Joined {String(data?.signup_date).split('T')[0]}</div>
            </div>
          </div>
        </div>

        {/* Buttons */}
        <div className="flex flex-wrap justify-center lg:justify-start mt-3 ml-auto my-auto">
          <button onClick={() => setModal(1)} className="button blue mb-1 mr-1">
            Update
          </button>
          {userRole === 'district' && (
            <button onClick={() => assignSubscription(data?._id)} className={`button teal mb-1 mr-1 ${userData?.district?.subscription === data?.subscription?._id ? 'opacity-50 pointer-events-none' : ''}`}>
              Assign Subscription
            </button>
          )}
          {userRole === 'admin' && (
            <button onClick={() => setModal(0)} className="button teal mb-1 mr-1">
              Transfer Subscription
            </button>
          )}
          <button onClick={() => setModal(3)} className="button orange mb-1 mr-1">
            Reset Password
          </button>
          {data?._id !== userData?._id && (
            <button onClick={() => setModal(2)} className="button red mb-1 mr-1">
              Delete
            </button>
          )}
        </div>

        {/* General Information */}
        <div className="bg-gray-100 p-6 mt-2 rounded-lg">
          <h2 className="h3">General</h2>
          <DetailSummary data={data} />
        </div>
      </div>
    </>
  ) : <Loading />
}

export default Page;