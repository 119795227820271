import { FormEvent, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import userApi from "../../apis/user";
import Modal from "../Modal";
import DetailSummary from "./DetailSummary";
import Avatar from "./Avatar";

interface IProps {
  data: User | null;
  isOpened: boolean;
  onClose: () => void;
}

const MigrateModal = (props: IProps) => {

  const { data, isOpened, onClose } = props;
  const navigate = useNavigate();

  const [ recipientData, setRecipientData ] = useState<User | null>(null);
  const [ searchData, setSearchData ] = useState<User[]>([]);

  const [ migrateStep, setMigrateStep ] = useState<number>(0); // false = need to select user, true = user selected
  const [ formData, setFormData ] = useState<{ [key: string]: string | number | boolean }>({
    recipientSearch: "",
    originId: "",
    recipientId: "",
    transferSubscription: true
  });

  // Effect for Mount
  useEffect(() => {
    setMigrateStep(0);
  }, [ data ]);

  // Effect for Recipient
  useEffect(() => {
    setFormData({ ...formData, originId: String(data?._id), recipientId: String(recipientData?._id) });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ recipientData, data ])

  // Migrate Methods
  const handleRecipientSearch = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    userApi.getList(0, 6, String(formData.recipientSearch))
      .then((r) => setSearchData([ ...(r?.data || []) ]))
      .catch((e) => toast.error(e));
  }

  const handleRecipientSelect = (index: number) => {
    setRecipientData(searchData[index]);
    setMigrateStep(1);
  }

  // Form Methods
  const handleSubmit  = () => {
    if (formData.originId === formData.recipientId)
      return toast.error("You cannot select yourself!");

    userApi.migrate(
      String(formData.originId), 
      String(formData.recipientId),
    ).then((r) => {
      if (!r.errors) {
        toast.success("Subscription transferred!");
        navigate(`/u/${formData.recipientId}`)
        onClose();
      } else 
        toast.error(r.errors);
    })
  };

  return (
    <>
      {/* Modal */}
      <Modal isOpened={isOpened} onClose={onClose}>
        <h2 className="h3 mb-3">Transfer Subscription</h2>
        {/* Migration Step: 0 */}
        {migrateStep === 0 && (
          <div className="h-auto xl:h-96">  
            <div className="font-semibold">Search</div>
            <form onSubmit={handleRecipientSearch}>
              <input type="text" name="search" placeholder="Search by Name, Email Address or Identifier" onChange={(e) => setFormData({ ...formData, recipientSearch: e.target.value })} />
            </form>

            {searchData.length > 0 && (
              <div className="mt-3">
                <div className="font-semibold">Results</div>
                <div className="text-sm opacity-80">Please select a user you wish to migrate to</div>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-3">
                  {searchData.map((r, i) => (
                    <button 
                      key={r._id} 
                      type="button" 
                      className={`flex text-left w-full hover:opacity-50 bg-gray-100 rounded-lg px-4 py-2 ${r._id === data?._id ? 'pointer-events-none opacity-50' : ''}`}
                      onClick={() => handleRecipientSelect(i)}
                    >
                      <Avatar data={r as User} className="w-12 h-12" />
                      <div className="pl-2 my-auto">
                        <div className="text-sm font-bold">{r.first_name} {r.last_name} ({r.user_name})</div>
                        <div className="text-gray-600 text-xs">{r.email}</div>
                      </div>
                    </button> 
                  ))}
                </div>
              </div>
            )}
          </div>
        )}

        {/* Migration Step: 1 */}
        {migrateStep === 1 && (
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <div>
              <div className="font-semibold">User Data</div>
              <DetailSummary data={data} highlightField="Subscription Id" />
            </div>

            <div>
              <div className="font-semibold">Recipient Data</div>
              {/* @ts-ignore */}
              <DetailSummary data={recipientData} highlightField="Subscription Id" />
            </div>

            <div className="col-span-full">
              This will transfer the subscription from the current user to the recipient user.
            </div>

            <div className="col-span-full flex space-x-2 justify-end">
              <button type="button" className="button default" onClick={() => setMigrateStep(migrateStep - 1)}>Back</button>
              <button type="button" className="button blue" onClick={handleSubmit}>Submit</button>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
}

export default MigrateModal;