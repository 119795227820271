import moment from "moment";
import { useUserContext } from "../../contexts/User.context";

interface IProps {
  data: User | null;
  highlightField?: string;
}

const DetailSummary = (props: IProps) => {

  const { data, highlightField } = props;
  const { userRole } = useUserContext();

  const items = [ 
    { label: "First Name", value: data?.first_name, visible: ['admin', 'district'].includes(userRole) },
    { label: "Last Name", value: data?.last_name, visible: ['admin', 'district'].includes(userRole) },
    { label: "User Name", value: data?.user_name, visible: ['admin', 'district'].includes(userRole) },
    { label: "Email", value: data?.email, visible: ['admin', 'district'].includes(userRole) },
    { label: "Role", value: data?.role, visible: ['admin'].includes(userRole) },
    { label: "Subscription", value: data?.subscription?.planName || data?.subscription?.plan || 'N/A', visible: ['admin'].includes(userRole) },
    { label: "Subscription", value: '#' + String(data?.subscription?._id || '000000').substring(0, 6), visible: ['district'].includes(userRole) },
    { label: "Subscription Id", value: data?.subscription?._id || '', visible: ['admin'].includes(userRole) },
    { label: "Curriculum", value: data?.curriculum_key, visible: ['admin'].includes(userRole) },
    { label: "Last Active", value: data?.last_active ? moment(data?.last_active || "").fromNow() : 'N/A', visible: ['admin', 'district'].includes(userRole) },
    { label: "Created", value: String(data?.signup_date).split('T')[0], visible: ['admin', 'district'].includes(userRole) },
  ];

  return data !== null ? (
    <div>
      {items.map((item) => item.visible && (
        <div key={item.label} className={`flex flex-wrap justify-between px-2 py-1 border-b border-dashed border-gray-500 text-sm lg:text-base ${highlightField === item.label ? 'bg-yellow-200' : 'bg-transparent'}`}>
          <div className="w-full lg:w-auto">
            {item.label}
          </div> 
          <div className="font-semibold w-full lg:w-auto">
            {item.value}
          </div>
        </div>
      ))}
    </div>
  ) : <></>
}

export default DetailSummary;