import { toast } from "react-toastify";
import districtApi from "../../apis/district";
import Modal from "../Modal";
import { FormEvent, useRef, useState } from "react";

interface IProps {
  isOpened: boolean;
  onClose: () => void;
  onRefresh: () => void;
}

const ImportModal = (props: IProps) => {

  const { isOpened, onRefresh, onClose } = props;
  const fileRef = useRef<HTMLInputElement>(null);

  const header = ['first_name', 'last_name', 'email_address', 'password'];
  const [ data, setData ] = useState<{ [key: string]: string }[]>([]);
  const [ errors, setErrors ] = useState<number[]>([]);
  const [ file, setFile ] = useState<File | null>(null);

  const parseCSV = (csv: string) => {
    const parsed = [];
    const rows = csv.split("\n");

    for (let i = 1; i < rows.length; i++) {
      const row = rows[i].split(",");

      parsed.push({
        [header[0]]: row[0],
        [header[1]]: row[1],
        [header[2]]: row[2],
        [header[3]]: row[3],
      });
    }

    setData(parsed);
  };

  const parseFile = (file: File) => {
    const fileReader = new FileReader();
    fileReader.readAsText(file);

    fileReader.onload = (e) => {
      const csv = fileReader.result;
      if (!csv) return;
      
      parseCSV(String(csv));
    }

    fileReader.readAsText(file);
  }

  const onSubmit = (e: FormEvent<HTMLFormElement>, file: File) => {
    e.preventDefault();

    if (file) {
      const formData = new FormData();
      formData.append('csvfile', file);

      districtApi.importUsers(formData)
        .then((r: any) => {
          if (!r?.error) {
            toast.success("Import completed!");
            if (fileRef?.current)
                fileRef.current.value = '';
            onRefresh();
            setData([]);
            onClose();
          } else {
            toast.error(r.error);
            setErrors(r?.badRows || []);
          }
        })
    } else 
      toast.error("Please select a file to upload.");
  };

  return (
    <>
      {/* Modal */}
      <Modal isOpened={isOpened} onClose={onClose} noScroll>
        <h2 className="h3 mb-3">Import from CSV</h2>
        <form method="post" className="grid grid-cols-1 gap-4" onSubmit={(e) => onSubmit(e, file as File)}>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <div className="rounded-xl bg-gray-50 p-4">
              <label>Upload your CSV file here.</label>
              <input
                ref={fileRef}
                type="file"
                name="file"
                className="w-full bg-white"
                onChange={(e) => {
                  setFile(e.target.files![0]);
                  parseFile(e.target.files![0]);
                }}
                accept=".csv"
              />
            </div>

            <div className="rounded-xl bg-yellow-50 text-yellow-900 p-4">
              <div className="font-bold">Instructions:</div>
              <p>
                Make sure your CSV file has the following columns: <br />
                <span className="font-semibold">first_name, last_name, email_address, password</span>
              </p>
            </div>
          </div>
          

          {data?.length > 0 && (
            <div className="col-span-full">
              <table className="table text-left">
                <thead className="p-1 bg-gray-200">
                  <tr>
                    <th>{header[0]}</th>
                    <th>{header[1]}</th>
                    <th>{header[2]}</th>
                    <th>{header[3]}</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    <tr key={index} className={`px-1 border-b ${errors.includes(index) ? 'bg-red-100' : ''}`}>
                      <td>{item?.first_name}</td>
                      <td>{item?.last_name}</td>
                      <td>{item?.email_address}</td>
                      <td>{item?.password}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {data?.length > 0 && <div className="text-sm opacity-80">If an email address is already in use, it will not be re-imported.</div>}

          <div className="col-span-full flex space-x-2 justify-end">
            <button type="submit" className={`button teal ${data?.length > 0 ? 'opacity-100' : 'opacity-50 pointer-events-none'}`}>Import Users</button>
          </div>
        </form>
      </Modal>
    </>
  );
}

export default ImportModal;