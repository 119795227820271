import axios from "axios";
import Config from "../Config";

const getList = async (skip: number, limit: number, search: string = '') => {
  const response = await axios.get(`${Config.apiUrl}/admin/district/list`, { params: { skip, limit, search } })
  const data = response.data;
  return data;
}

const getAnalyticsById = async (_id: string = '') => {
  const response = await axios.get(`${Config.apiUrl}/admin/district/analytics?_id=${_id}`)
  const data = response.data;
  return data;
};

const getOne = async (districtId: string = '') => {
  const response = await axios.get(`${Config.apiUrl}/district/${districtId}`)
  const data = response.data;
  return data;
}

const createAdmin = async (formData: { [key: string]: string }) => {
  const response = await axios.post(
    `${Config.apiUrl}/admin/district/user/createAdmin`, 
    new URLSearchParams(Object.entries(formData)).toString(),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    }
  );
  const data = response.data;
  return data;
};

const importUsers = async (formData: FormData) => {
  const response = await axios.post(
    `${Config.apiUrl}/admin/district/user/importUsers`, 
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  const data = response.data;
  return data;
};

export default {
  getList,
  getAnalyticsById,
  getOne,
  createAdmin,
  importUsers
}