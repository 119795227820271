
const conf = {
  name: 'Math Games ACP',
  baseUrl: 'https://www.mathgames.com',
  apiUrl: 
    process.env.NODE_ENV === "production" 
      ? 'https://www.mathgames.com' 
      : 'http://local.mathgames.com:8888',
};

export default conf;
